import { computed, nextTick, onMounted, onUnmounted, ref, watchEffect } from "vue";
import { Key } from "@/types/Keyboard";
import { rus } from "@/assets/keys/rus";
import { eng } from "@/assets/keys/eng";
import { num } from "@/assets/keys/num";
import { onlyNum } from "@/assets/keys/only-num";
import { ukr } from "@/assets/keys/ukr";

interface Params {
  keyboardType: string;
  keyboardOffset: number; // keyboard offset from the screen left side
  keyOffset: number; // keyboard key margin
  onClick: (key: Key) => void;
}

export const useKeyboard = (params: Params) => {
  const { keyboardType, keyboardOffset, keyOffset, onClick } = params;

  const keys = ref<Key[][]>([]);
  const AVAILABLE_LANGUAGES = ['en', 'ua', 'ru'] as const;
  type Language = typeof AVAILABLE_LANGUAGES[number];

  const acceptLanguages = computed<Language[]>(() => {
    const envLanguages = process.env.VUE_APP_ACCEPT_LANGUAGES
        ?.split(',')
        .filter((l): l is Language => AVAILABLE_LANGUAGES.includes(l as Language));

    return envLanguages && envLanguages.length ? envLanguages : [];
  });
  const keysSetDefault: { [key: string]: Key[][] } = {
    ua: ukr,
    ru: rus,
    en: eng,
    numbers: num,
    onlyNum: onlyNum
  };
  const keysSet = computed(() => {
    const allowedLanguages = acceptLanguages.value;
    return {
      ...Object.fromEntries(
          allowedLanguages
              .filter(lang => keysSetDefault[lang])
              .map(lang => [lang, keysSetDefault[lang]])
      ),
      numbers: keysSetDefault.numbers,
      onlyNum: keysSetDefault.onlyNum
    };
  });

  const KeyActions: { [key: string]: string } = {
    Backspace: 'backspace',
    ArrowLeft: 'backward',
    ArrowRight: 'forward'
  };

  const size = computed((): string => ['num', 'onlyNum'].includes(keyboardType) ? 's' : 'm');

  const keySize = computed((): number => {
    const maxCols = 12;
    const maxWidth = document.documentElement.clientWidth - keyboardOffset * 2 - keyOffset * (maxCols - 1);
    const maxItemWidth = Math.floor(maxWidth / maxCols);

    const maxRows = 4;
    const maxHeight = document.documentElement.clientHeight - 524 - keyOffset * (maxRows - 1);
    const maxItemHeight = Math.floor(maxHeight / maxRows);

    return Math.min(maxItemWidth, maxItemHeight);
  });

  const symbols = computed((): string => {
    let symbols = '';
    keys.value?.map(set => {
      set?.map(item => {
        symbols += item.symbol && !item.action ? item.symbol : '';
      });
    });

    return symbols.toLowerCase();
  });

  const findKeyByValue = (set: Key[][], value: string, param = 'symbol' as 'symbol' | 'action'): Key | undefined => {
    for (const item of set) {
      for (const key of item) {
        if (key[param]?.toLowerCase() === value.toLowerCase()) {
          return key;
        }
      }
    }

    return undefined;
  };

  const onKeyboardBtnClick = (e: Event) => {
    const key = (e as KeyboardEvent).key;
    let item: Key | undefined = undefined;
    if (/\d/.test(key)) {
      item = findKeyByValue(num, key);
    } else if (symbols.value.includes(key)) {
      item = findKeyByValue(keys.value, key);
    } else if (['\'', '"', '`'].includes(key)) {
      item = findKeyByValue(keys.value, '‘');
    } else if (Object.keys(KeyActions).includes(key)) {
      item = findKeyByValue(keys.value, KeyActions[key], 'action');
    }

    item && onClick(item);
  };

  onMounted(() => document.documentElement.addEventListener('keydown', onKeyboardBtnClick));

  onUnmounted(() => document.documentElement.removeEventListener('keydown', onKeyboardBtnClick));

  watchEffect(() => keys.value = keysSet.value[keyboardType]);

  nextTick(() => {
    console.log(document.getElementsByClassName('keyboard')?.[0]?.clientHeight);
  })

  return {
    keys, keysSet, KeyActions, size, keySize, symbols,
    findKeyByValue, onKeyboardBtnClick
  }
};
