import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { computed, onMounted, provide, ref, watch } from "vue";
import InnerLoader from "@/components/Loader/InnerLoader.vue";
import BreadcrumbsComponent from "@/components/Text/BreadcrumbsComponent.vue";
import { DataField } from "@/types/Form";
import { StepperItem } from "@/types/Stepper";
import StepperComponent from "@/components/Stepper/StepperComponent.vue";
import DataInput from "@/components/Input/DataInput.vue";
import { InputValue } from "@/types/Keyboard";
import { useRouter } from "vue-router";
import MessagePopup from "@/components/Popups/MessagePopup.vue";
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";


export default /*@__PURE__*/_defineComponent({
  __name: 'QueueFormView',
  emits: ['set-footer-buttons'],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const router = useRouter();
const appStore = useAppStore();
const { isEditMode, enteredValues, currentStep, keyboardType, isAgree } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { info, fields, error } = storeToRefs(terminalStore);

const isLoading = ref(true);
const value = ref<InputValue[]>([]);
const isNextDisabled = ref(false);

const stepperItems = computed((): StepperItem[] => {
  return fields.value?.map(item => {
    return { id: item.dataFieldId, text: item.title, isRequired: item.required }
  }) ?? [];
});
const currentField = computed((): DataField => fields.value[currentStep.value]);
const currentKeyboardType = computed((): string => {
  if (currentField.value?.type === 'ph') {
    return 'numbers';
  } else if (currentField.value?.type === 'e') {
    return 'en';
  } else {
    return keyboardType.value ? keyboardType.value : 'ua';
  }
});
const mask = computed((): string => {
  if (currentField.value?.type === 'ph' && currentField.value?.inputMask) {
    const countryCode = info.value?.settings?.countryCode ?? '';
    return currentField.value.inputMask.replace('{countryCode}', countryCode);
  } else {
    return currentField.value?.inputMask ?? '';
  }
});

const onBackClick = () => {
  if (currentStep.value === 0) {
    router.push('queue-period');
    appStore.removeLastPathItem();
  } else {
    currentStep.value = currentStep.value - 1;
  }
}

const onNextClick = () => {
  if (isEditMode.value) {
    currentStep.value = fields.value.length - 1;
    isEditMode.value = false;
    router.push('queue-results');
  } else if (currentStep.value + 1 < fields.value.length) {
    currentStep.value = currentStep.value + 1;
  } else {
    router.push('queue-results');
  }
};

const setValue = (newValue: InputValue[], isValid: boolean) => {
  value.value = newValue;
  if (!currentField.value?.required) {
    isNextDisabled.value = newValue.length ? !isValid : false;
  } else {
    isNextDisabled.value = !isValid || !newValue.length;
  }

  appStore.setEnteredValue(currentField.value.dataFieldId, {
    value: newValue,
    label: currentField.value.format,
    type: currentField.value.type
  });
};

const onClose = async () => {
  error.value = '';
  await router.push('/');
};

onMounted(async () => {
  emits('set-footer-buttons', [
    { type: 'back', handler: onBackClick },
    { type: 'main' },
    { type: 'next', isDisabled: true, handler: onNextClick },
  ]);

  await terminalStore.getTQueueForm();
  if (fields.value.length) {
    appStore.addPathItem('queue-form');
    isLoading.value = false;
  } else {
    await router.push('queue-results');
  }
});

watch([() => value.value, () => currentField.value, () => isNextDisabled.value, () => isAgree.value], () => {
  const isDisabled = isNextDisabled.value || currentField.value?.hint === 'firstName' && !isAgree.value;
  emits('set-footer-buttons', [
    { type: 'back', handler: onBackClick },
    { type: 'main' },
    { type: 'next', isDisabled, handler: onNextClick },
  ]);
  window.dispatchEvent(new CustomEvent('set-button-state', { detail: isDisabled }));
}, { deep: true });

provide('on-prev-click', onBackClick);
provide('on-next-click', onNextClick);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(InnerLoader, { isLoading: isLoading.value }, null, 8, ["isLoading"]),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(_ctx.$attrs.class)
    }, [
      _createVNode(BreadcrumbsComponent),
      _createVNode(StepperComponent, {
        items: stepperItems.value,
        "current-item": +_unref(currentStep)
      }, null, 8, ["items", "current-item"]),
      (currentField.value)
        ? (_openBlock(), _createBlock(DataInput, {
            class: "data-input",
            keyboard: currentKeyboardType.value,
            mask: mask.value,
            initial: _unref(enteredValues)[currentField.value.dataFieldId]?.value ?? [],
            "is-keyboard-shown-default": true,
            key: `${_unref(currentStep)}${currentField.value.dataFieldId}`,
            onSetValue: setValue
          }, null, 8, ["keyboard", "mask", "initial"]))
        : _createCommentVNode("", true)
    ], 2), [
      [_vShow, !isLoading.value && !_unref(error)]
    ]),
    (_unref(error))
      ? (_openBlock(), _createBlock(MessagePopup, {
          key: 0,
          message: _unref(error),
          onClose: onClose
        }, null, 8, ["message"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})