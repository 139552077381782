import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

import InputComponent from '@/components/Input/InputComponent.vue';
import KeyboardComponent from '@/components/Keyboard/KeyboardComponent.vue';
import { computed, onMounted, onUpdated, PropType, ref, watch, watchEffect } from 'vue';
import HintsComponent from '@/components/Hints/HintsComponent.vue';
import { InputValue, Key } from '@/types/Keyboard';
import { DataField } from '@/types/Form';
import { firstName } from '@/assets/files/first-name';
import { lastName } from '@/assets/files/last-name';
import { middleName } from '@/assets/files/middle-name';
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";
import EquickFieldPermission from "@/components/Equick/Input/EquickFieldPermission.vue";

type Handlers = { [key: string]: () => void };


export default /*@__PURE__*/_defineComponent({
  __name: 'DataInput',
  props: {
  maxLength: {
    type: Number,
    default: 100
  },
  label: String,
  keyboard: String,
  mask: {
    type: String,
    default: ''
  },
  initial: {
    type: Array as PropType<InputValue[]>,
    default: () => []
  },
  hasHints: {
    type: Boolean,
    default: true
  },
  hasButtons: {
    type: Boolean,
    default: true
  },
  hasToggleButton: Boolean,
  isKeyboardShownDefault: Boolean,
},
  emits: ['set-value', 'set-keyboard-state'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emits = __emit;

const appStore = useAppStore();
const { currentStep, keyboardType } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { fields } = storeToRefs(terminalStore);

const hintSet: { [key: string]: string[] } = { firstName, lastName, middleName };

const currentField = computed((): DataField => fields.value?.[currentStep.value] ?? {});
const currentValue = ref<InputValue[]>(props.initial as InputValue[]);
const startMaskValue = ref<InputValue[]>([]);
const hints = ref<string[]>([]);
const isValid = ref(false);
const isKeyboardShown = ref(props.isKeyboardShownDefault);
const lastKey = ref<Key>({} as Key);
const currentPosition = ref((props.initial?.length ?? 0) - 1);
const currentKeyboardType = ref(props.keyboard);
const prevType = ref('');
const hintsKey = ref(0);

const onKeyClick = (key: any) => {
  lastKey.value = key;
  if (key.action) {
    handlers.value?.[key.action]();
  } else {
    handlers.value.symbol();
  }
};

const changeType = () => {
  if (['e', 'ph'].includes(currentField.value?.type)) {
    return;
  }

  const type = lastKey.value.symbol ? lastKey.value.symbol : lastKey.value.action;
  if (type === 'letters') {
    currentKeyboardType.value = prevType.value;
  } else {
    currentKeyboardType.value = type;
  }

  const acceptLanguages = computed<string[]>(() => {
    const envLanguages = process.env.VUE_APP_ACCEPT_LANGUAGES
        ?.split(',')
        .filter(Boolean);

    return envLanguages && envLanguages.length ? envLanguages : [];
  });

  const nextLanguages = computed<Record<string, string>>(() => {
    const languages = acceptLanguages.value;
    const result: Record<string, string> = {};

    languages.forEach((lang, index) => {
      const nextIndex = (index + 1) % languages.length;
      result[lang] = languages[nextIndex];
    });

    return result;
  });
  if (lastKey.value.action === 'language') {
    prevType.value = nextLanguages.value[lastKey.value.symbol];
    currentKeyboardType.value = nextLanguages.value[lastKey.value.symbol];
    keyboardType.value = nextLanguages.value[lastKey.value.symbol];
  }
};

const changeValue = () => {
  if (currentValue.value.length >= props.maxLength) {
    return;
  }

  const value = { value: lastKey.value.symbol, isMaskSymbol: false, index: currentValue.value.length };
  if (currentPosition.value >= 0 && currentPosition.value !== currentValue.value.length - 1) {
    currentValue.value.splice(currentPosition.value + 1, 0, value);
  } else {
    currentValue.value.push(value);
  }

  if (props.mask) {
    setFormattedValue();
    setCurrentPosition('add');
  } else {
    currentPosition.value++;
  }

  validate();
};

const deleteSymbol = () => {
  if (startMaskValue.value?.length && currentPosition.value < startMaskValue.value.length) {
    return;
  }

  currentValue.value.splice(currentPosition.value, 1);

  if (props.mask) {
    setFormattedValue();
    setCurrentPosition('delete');
  } else {
    currentPosition.value--;
    if (currentPosition.value === -1 && currentValue.value.length) {
      currentPosition.value = 0;
    }
  }

  validate();
};

const clearValue = () => {
  isKeyboardShown.value = false;
  currentValue.value = [];

  if (props.mask) {
    setFormattedValue();
  } else {
    currentPosition.value = -1;
  }

  validate();
};

const goForward = () => {
  if (currentPosition.value !== currentValue.value.length - 1) {
    currentPosition.value++;

    if (currentValue.value[currentPosition.value].isMaskSymbol) {
      goForward();
    }
  }
};

const goBackward = () => {
  if (currentPosition.value > (startMaskValue.value?.length ?? 1) - 1) {
    currentPosition.value--;

    if (currentValue.value[currentPosition.value].isMaskSymbol) {
      goBackward();
    }
  }
};

const getSplittedValue = (value: string) => {
  return `${ value }`.split('').map((item, index) => ({ value: item, isMaskSymbol: false, index }));
};

const setPosition = (position: number) => currentPosition.value = position;

const setHintValue = (value: string) => {
  console.log(value)
  value = value.toUpperCase();
  const spaceItem = [...currentValue.value].reverse().find(item => item.value === ' ');
  if (spaceItem) {
    const afterSpaceSymbols = currentValue.value.slice(spaceItem.index + 1);
    const afterSpaceText = afterSpaceSymbols.map(item => item.value).join('');
    if (value.startsWith(afterSpaceText)) {
      currentValue.value.splice(spaceItem.index + 1);
    }

    currentValue.value = [...currentValue.value, ...getSplittedValue(value)];
    currentPosition.value = currentValue.value.length - 1;
  } else {
    currentValue.value = getSplittedValue(value);
    currentPosition.value = value.length;
  }
};

const setFormattedValue = () => {
  const formattedValue: InputValue[] = [];
  const value = currentValue.value.filter(item => !item.isMaskSymbol);
  let counter = 0;
  for (let i = 0; i < props.mask.length; i++) {
    if (counter >= value.length) {
      break;
    }

    if (props.mask[i] === '#') {
      formattedValue.push({ ...value[counter++], index: i });
    } else {
      formattedValue.push({ value: props.mask[i], isMaskSymbol: true, index: i });
    }
  }

  currentValue.value = formattedValue;
};

const setCurrentPosition = (action: string) => {
  if (currentValue.value.length <= props.mask.length) {
    if (currentPosition.value + 1 === currentValue.value.length - 1) {
      currentPosition.value = currentValue.value.length - 1;
    } else if (currentValue.value.length !== props.mask.length) {
      let item: InputValue = {} as InputValue;
      if (action === 'add') {
        item = currentValue.value.slice(currentPosition.value + 1).find(item => !item.isMaskSymbol) ?? {} as InputValue;
      } else if (action === 'delete') {
        item = currentValue.value.slice(0, currentPosition.value).reverse().find(item => !item.isMaskSymbol) ?? {} as InputValue;
      }
      currentPosition.value = currentValue.value.indexOf(item);
    } else if (currentPosition.value + 1 < props.mask.length) {
      currentValue.value.length = props.mask.length;
      const item = currentValue.value.slice(currentPosition.value + 1).find(item => !item.isMaskSymbol) ?? {} as InputValue;
      currentPosition.value = item.index;
    }
  }
};

const setStartMaskValue = (isInitial = false) => {
  if (isInitial && props.initial?.length) {
    currentValue.value = props.initial;
    currentPosition.value = props.initial.length - 1;
  } else if (props.mask) {
    currentValue.value = [...startMaskValue.value];
    currentPosition.value = startMaskValue.value.length - 1;
  }
};

const validate = () => {
  const { inputRegExp, required } = currentField.value;
  console.log(444, inputRegExp, required);
  if (required && !currentValue.value.length) {
    isValid.value = false;
  } else if ((required || props.initial?.length) && inputRegExp) {
    const strValue = currentValue.value.map(item => item.value).join('');
    if (currentField.value?.type === 'ph') {
      const phone = strValue.slice((startMaskValue.value?.length - 1) ?? 0).replaceAll(' ', '').replaceAll('-', '');
      console.log(444, phone, inputRegExp);
      isValid.value = new RegExp(inputRegExp).test(phone);
    } else {
      isValid.value = new RegExp(inputRegExp).test(strValue);
    }
  } else {
    isValid.value = true;
  }
};

const onFieldClick = (e: Event) => {
  if ((e.target as HTMLElement)?.getAttribute('class')?.includes('input__field')) {
    isKeyboardShown.value = !isKeyboardShown.value;
  }
};

const handlers = ref<Handlers>({
  language: changeType,
  numbers: changeType,
  letters: changeType,
  symbol: changeValue,
  backspace: deleteSymbol,
  forward: goForward,
  backward: goBackward
});

onMounted(() => {
  if (props.mask) {
    startMaskValue.value = [];
    for (let i = 0; i < props.mask.length; i++) {
      if (props.mask[i] !== '#') {
        startMaskValue.value.push({ value: props.mask[i], isMaskSymbol: true, index: i });
      } else {
        break;
      }
    }
  }

  validate();
  setStartMaskValue(true);
});

watch(() => currentValue.value, (value: InputValue[]) => {
  console.log(2)
  emits('set-value', value, isValid.value);
  if (!value.length) {
    setStartMaskValue();
  }
}, { deep: true });

watch(() => isKeyboardShown.value, () => {
  console.log(1)
  emits('set-keyboard-state', isKeyboardShown.value);
  if (!isKeyboardShown.value) {
    clearValue();
  }
});

watchEffect(() => {
  console.log('hint 2');
  if (currentField.value?.hint) {
    hints.value = hintSet?.[currentField.value.hint] ?? [];
  } else {
    hints.value = [];
  }
});

onUpdated(() => console.log('data'));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(InputComponent, {
      "has-toggle-button": __props.hasToggleButton,
      "has-buttons": __props.hasButtons,
      "is-valid": isValid.value,
      "current-value": currentValue.value,
      "current-position": currentPosition.value,
      "toggle-state": isKeyboardShown.value,
      onClearValue: clearValue,
      onChangeToggleState: _cache[0] || (_cache[0] = v => isKeyboardShown.value = v),
      onSetPosition: setPosition,
      onClick: onFieldClick
    }, null, 8, ["has-toggle-button", "has-buttons", "is-valid", "current-value", "current-position", "toggle-state"]),
    (__props.hasHints && currentField.value?.type === 't')
      ? (_openBlock(), _createBlock(HintsComponent, {
          key: hintsKey.value,
          items: hints.value,
          value: currentValue.value,
          onSetValue: setHintValue
        }, null, 8, ["items", "value"]))
      : _createCommentVNode("", true),
    (currentField.value?.hint === 'firstName')
      ? (_openBlock(), _createBlock(EquickFieldPermission, { key: 1 }))
      : _createCommentVNode("", true),
    (isKeyboardShown.value)
      ? (_openBlock(), _createBlock(KeyboardComponent, {
          key: 2,
          "keyboard-type": currentKeyboardType.value,
          onKeyClick: onKeyClick
        }, null, 8, ["keyboard-type"]))
      : _createCommentVNode("", true)
  ]))
}
}

})