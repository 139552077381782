<template>
  <inner-loader :isLoading="isLoading" />
  <div v-show="!isLoading && !error" :class="$attrs.class">
    <breadcrumbs-component />
    <stepper-component :items="stepperItems" :current-item="+currentStep" />
    <data-input v-if="currentField" class="data-input" :keyboard="currentKeyboardType" :mask="mask"
                :initial="enteredValues[currentField.dataFieldId]?.value ?? []" :is-keyboard-shown-default="true"
                :key="`${currentStep}${currentField.dataFieldId}`" @set-value="setValue" />
  </div>
  <message-popup v-if="error" :message="error" @close="onClose" />
</template>

<script lang="ts" setup>
import { computed, onMounted, provide, ref, watch } from "vue";
import InnerLoader from "@/components/Loader/InnerLoader.vue";
import BreadcrumbsComponent from "@/components/Text/BreadcrumbsComponent.vue";
import { DataField } from "@/types/Form";
import { StepperItem } from "@/types/Stepper";
import StepperComponent from "@/components/Stepper/StepperComponent.vue";
import DataInput from "@/components/Input/DataInput.vue";
import { InputValue } from "@/types/Keyboard";
import { useRouter } from "vue-router";
import MessagePopup from "@/components/Popups/MessagePopup.vue";
import { useAppStore } from "@/store/app.store";
import { storeToRefs } from "pinia";
import { useTerminalStore } from "@/store/terminal.store";

const emits = defineEmits(['set-footer-buttons']);

const router = useRouter();
const appStore = useAppStore();
const { isEditMode, enteredValues, currentStep, keyboardType, isAgree } = storeToRefs(appStore);
const terminalStore = useTerminalStore();
const { info, fields, error } = storeToRefs(terminalStore);

const isLoading = ref(true);
const value = ref<InputValue[]>([]);
const isNextDisabled = ref(false);

const stepperItems = computed((): StepperItem[] => {
  return fields.value?.map(item => {
    return { id: item.dataFieldId, text: item.title, isRequired: item.required }
  }) ?? [];
});
const currentField = computed((): DataField => fields.value[currentStep.value]);
const currentKeyboardType = computed((): string => {
  if (currentField.value?.type === 'ph') {
    return 'numbers';
  } else if (currentField.value?.type === 'e') {
    return 'en';
  } else {
    return keyboardType.value ? keyboardType.value : 'ua';
  }
});
const mask = computed((): string => {
  if (currentField.value?.type === 'ph' && currentField.value?.inputMask) {
    const countryCode = info.value?.settings?.countryCode ?? '';
    return currentField.value.inputMask.replace('{countryCode}', countryCode);
  } else {
    return currentField.value?.inputMask ?? '';
  }
});

const onBackClick = () => {
  if (currentStep.value === 0) {
    router.push('queue-period');
    appStore.removeLastPathItem();
  } else {
    currentStep.value = currentStep.value - 1;
  }
}

const onNextClick = () => {
  if (isEditMode.value) {
    currentStep.value = fields.value.length - 1;
    isEditMode.value = false;
    router.push('queue-results');
  } else if (currentStep.value + 1 < fields.value.length) {
    currentStep.value = currentStep.value + 1;
  } else {
    router.push('queue-results');
  }
};

const setValue = (newValue: InputValue[], isValid: boolean) => {
  value.value = newValue;
  if (!currentField.value?.required) {
    isNextDisabled.value = newValue.length ? !isValid : false;
  } else {
    isNextDisabled.value = !isValid || !newValue.length;
  }

  appStore.setEnteredValue(currentField.value.dataFieldId, {
    value: newValue,
    label: currentField.value.format,
    type: currentField.value.type
  });
};

const onClose = async () => {
  error.value = '';
  await router.push('/');
};

onMounted(async () => {
  emits('set-footer-buttons', [
    { type: 'back', handler: onBackClick },
    { type: 'main' },
    { type: 'next', isDisabled: true, handler: onNextClick },
  ]);

  await terminalStore.getTQueueForm();
  if (fields.value.length) {
    appStore.addPathItem('queue-form');
    isLoading.value = false;
  } else {
    await router.push('queue-results');
  }
});

watch([() => value.value, () => currentField.value, () => isNextDisabled.value, () => isAgree.value], () => {
  const isDisabled = isNextDisabled.value || currentField.value?.hint === 'firstName' && !isAgree.value;
  emits('set-footer-buttons', [
    { type: 'back', handler: onBackClick },
    { type: 'main' },
    { type: 'next', isDisabled, handler: onNextClick },
  ]);
  window.dispatchEvent(new CustomEvent('set-button-state', { detail: isDisabled }));
}, { deep: true });

provide('on-prev-click', onBackClick);
provide('on-next-click', onNextClick);

</script>

<style lang="scss" scoped>
.data-input {
  @extend .flex-col;
  height: 100%;
}
</style>
