import { defineStore } from 'pinia';
import { ref } from 'vue';
import { InitResult } from '@/types/Init';
import { EnteredValue, EnteredValues } from '@/types/Keyboard';
import { useApi } from '@/composable/useApi';
import { useTerminalStore } from '@/store/terminal.store';
import i18n from '@/i18n';

export const useAppStore = defineStore('appStore', () => {
  const { api, getErrorMessage } = useApi();
  const terminalStore = useTerminalStore();

  const language = ref('');
  const defaultLanguage = ref('');
  const acceptLanguages = ref<string[]>([]);
  const keyboardType = ref('');
  const currentGroup = ref('');
  const token = ref<string>('');
  const initError = ref('');
  const initResult = ref<InitResult>({});
  const serverTime = ref<null | Date>(null);
  const path = ref<string[]>([]);
  const enteredValues = ref<EnteredValues>({});
  const isKeyboardShown = ref(false);
  const currentStep = ref(0);
  const pageIndent = ref(0);
  const isSseError = ref(false);
  const isEditMode = ref(false);
  const isAgree = ref(true);
  const isTimeoutError = ref(false);
  const screenOrientation = ref('');

  const initialize = async () => {
    try {
      const response: InitResult = await api.get('/initialize', { isGlobalApi: false });
      token.value = response?.token ?? '';
      initError.value = response?.error_message ?? response?.error_name ?? '';
      initResult.value = response;
    } catch (err) {
      initError.value = getErrorMessage(err);
      initResult.value = {};
    }
  };

  const clearUserData = () => {
    const lang = terminalStore.info?.settings?.language ?? localStorage.getItem('locale') ?? defaultLanguage.value;
    currentStep.value = 0;
    enteredValues.value = {} as EnteredValues;
    path.value = [];
    language.value = lang;
    keyboardType.value = lang;
  };

  const setLanguage = (value: string, isInitial = false) => {
    if (isInitial && acceptLanguages.value?.includes(language.value)) {
      i18n.global.locale.value = language.value;
      return;
    }

    const lang = acceptLanguages.value?.includes(value) ? value : defaultLanguage.value;
    language.value = lang;
    keyboardType.value = lang;
    i18n.global.locale.value = lang;
  };

  const setLanguageData = () => {
    if (process.env.VUE_APP_ACCEPT_LANGUAGES) {
      acceptLanguages.value = process.env.VUE_APP_ACCEPT_LANGUAGES?.split(',') ?? [];
    } else {
      acceptLanguages.value = [];
    }

    console.log(process.env);
    defaultLanguage.value = process.env.VUE_APP_DEFAULT_LANGUAGE ?? acceptLanguages.value?.[0] ?? '';
  };

  const addPathItem = (newValue: string) => {
    if (path.value[path.value.length - 1] !== newValue && !!newValue) {
      console.log(`ADD_PATH_ITEM: --${ newValue }--`);
      path.value.push(newValue);
    }
  };

  const removeLastPathItem = () => {
    console.log(`REMOVE_PATH_ITEM`);
    path.value.pop();
  };

  const setLastPathItem = (pathItem: string) => {
    const index = path.value.indexOf(pathItem);
    if (index >= 0) {
      console.log(`SET_LAST_PATH_ITEM: --${ pathItem }--`);
      path.value = path.value.slice(0, index + 1);
    }
  }

  const setEnteredValue = (key: string | number, value: EnteredValue) => {
    enteredValues.value[key] = value;
  };

  return {
    language, defaultLanguage, acceptLanguages, keyboardType, currentGroup, token, initError, initResult, serverTime,
    path, enteredValues, currentStep, pageIndent, isSseError, isEditMode, isAgree, isKeyboardShown, screenOrientation,
    isTimeoutError,

    initialize, clearUserData, setLanguage, setLanguageData, addPathItem, removeLastPathItem, setEnteredValue,
    setLastPathItem
  };
}, { persist: true });
