import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { computed } from 'vue';
import { useAppStore } from '@/store/app.store';
import i18n from "@/i18n";

axios.defaults.timeout = 15000;
axios.defaults.timeoutErrorMessage = 'timeout';
axios.interceptors.response.use(undefined, async function axiosRetryInterceptor(err) {
  const appStore = useAppStore();
  appStore.isTimeoutError = true;
  return Promise.reject(err);
});

/*interface Config {
  headers: {
    Authorization: string;
    lang: string;
  };
  withCredentials: boolean;
}*/

interface Arguments {
  data?: JSONValue;
  isGlobalApi?: boolean;
}

export type JSONValue = string | number | boolean | undefined | null | { [key: string]: JSONValue } | JSONValue[];

enum RequestTypes {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}

export const useApi = () => {
  const appStore = useAppStore();

  const apiToken = computed((): string => {
    const value = appStore.token !== '' ? appStore.token : process.env.VUE_APP_TOKEN ?? '';
    return value?.length ? `Bearer ${value}` : '';
  });

  const apiLanguage = computed((): string | undefined => {
    //const value = appStore.language !== '' ? appStore.language : process.env.VUE_APP_I18N_LOCALE ?? '';
    return appStore?.language.length>0 ? appStore.language: undefined;
  });

  const apiUrl = computed((): string => {
    return process.env.NODE_ENV === 'development' ? process.env.VUE_APP_DEVELOPER_SERVER_API : process.env.VUE_APP_SERVER_API;
  });

  const config = computed((): AxiosRequestConfig => ({
    headers: {
      Authorization: apiToken.value,
      lang: apiLanguage.value
    },
    withCredentials: true
  }));

  const getUrl = (url: string, isGlobalApi = true) =>
    process.env.VUE_APP_SERVER_TYPE === 'local'
      ? `${ isGlobalApi ? '/api/terminal-queue-token' : '' }${ url }`
      //? `http://localhost:3001${ isGlobalApi ? "/api/terminal-queue-token" : "" }${ url }`
      : `${ apiUrl.value }/terminal-queue-token${ url }`;

  const getResponse = (response: AxiosResponse) => response?.data;

  const api: { [key in RequestTypes]: (url: string, args?: Arguments) => Promise<any> } = {
    get: async (url, { isGlobalApi = false } = {}) =>
      getResponse(await axios.get(getUrl(url, isGlobalApi), config.value)),
    post: async (url, { data = {}, isGlobalApi = false } = {}) =>
      getResponse(await axios.post(getUrl(url, isGlobalApi), data, config.value)),
    put: async (url, { data = {}, isGlobalApi = false } = {}) =>
      getResponse(await axios.put(getUrl(url, isGlobalApi), data, config.value)),
    patch: async (url, { data = {}, isGlobalApi = false } = {}) =>
      getResponse(await axios.patch(getUrl(url, isGlobalApi), data, config.value)),
    delete: async (url, { isGlobalApi = false } = {}) =>
      getResponse(await axios.delete(getUrl(url, isGlobalApi), config.value))
  };

  const getErrorMessage = (error: unknown): string => {
    if (((error as AxiosError)?.response as AxiosResponse)?.data) {
      const data = ((error as AxiosError).response as AxiosResponse).data;
      if (data.error) {
        return i18n.global.t(`errors.${ data.error }`);
      } else {
        return data?.message ?? '';
      }
    } else if ((error as AxiosError)?.message) {
      return (error as AxiosError).message;
    } else {
      return 'Error';
    }
  };

  return { api, apiUrl, apiToken, apiLanguage, config, getErrorMessage };
};
